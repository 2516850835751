

const NoPage = () =>{

   return (
      <div>
         nopage
      </div>
   )
}

export default NoPage;